<template>
  <div id="login">
    <div id="bg">
      <div id="frame">
        <div class="title-cross"></div>
        <div class="title">登录</div>
        <el-input v-model="account" placeholder="请输入账号" clearable @keydown.enter="login()"></el-input>
        <el-input v-model="password" placeholder="请输入密码" clearable @keydown.enter="login()" show-password></el-input>
        <el-input v-model="otp_code" placeholder="谷歌验证器" clearable @keydown.enter="login()"></el-input>
        <el-button id="send" type="primary" @click="login()">登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
// import { login } from "@/api/login/login";
import httpClient from "@/config/httpClient";
// import {useStore} from "vuex"
export default {
  name: "login",
  data() {
    return {
      account: "", //账号
      password: "", //密码
      otp_code:"",
    };
  },
  mounted() {
    this.isLogin();
  },
  methods: {
    login() {
      httpClient("login").post({ "account": this.account, "password": this.password,"otp_code":this.otp_code},)
        .then((res) => {
          if (res.code == 0) {
            localStorage.setItem("token", res.data.token); //登录凭证
            localStorage.setItem("uploader_api", res.data.uploader_api || ""); //短视频上传服务器
            localStorage.setItem("uid", res.data.admin_id); //管理员id
            localStorage.setItem("nickname", res.data.nickname); //管理员昵称
            localStorage.setItem("is_gd", res.data.is_gd); //管理员昵称
            localStorage.setItem("share_link", res.data.share_link); //管理员昵称
            this.$message({ message: "登录成功", type: "success" });
            this.$store.saveSysInfo({
              pic_cdn:res.data.pic_cdn,
              uploader_api:res.data.uploader_api,
              video_cdn:res.data.video_cdn,
            })
            this.$router.push("/home");
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //判断登录信息是否存在
    isLogin() {
      if (localStorage.getItem("token")) this.$router.push("/home");
    },
  },
};
</script>

<style scoped>
#login {
  width: 100%;
  height: 100%;
  background: url("~@/assets/images/login/bg.png") center center no-repeat;
  background-size: 100% 100%;
  position: fixed;
}
#bg {
  width: 80%;
  height: 80%;
  margin: 5% 10%;
  background: url("~@/assets/images/login/bg2.png") center center no-repeat;
  background-size: 100% 100%;
  position: fixed;
}
#frame {
  width: 20%;
  position: relative;
  top: 10%;
  margin: 0 3%;
}
#frame .el-input {
  margin-top: 5%;
  height: 60px;
}
#frame .el-input >>> .el-input__inner {
  border: none;
  height: 60px;
  line-height: 60px;
  background: rgb(247, 244, 250);
  border-radius: 50px;
  padding: 0 40px;
  font-size: 18px;
}
#frame .el-input >>> .el-input__suffix {
  margin-right: 40px;
}
#frame >>> i {
  margin-top: 10px;
}
#send {
  margin: 5% 0 0 0;
  width: 50%;
  height: 50px;
  border-radius: 50px;
  background: rgb(43, 109, 222);
}
.title-cross {
  height: 7px;
  width: 50px;
  background: rgb(43, 109, 222);
  margin-left: 20px;
}
.title {
  font-size: 30px;
  font-weight: 700;
  margin: 30px 0 15px 20px;
}
</style>